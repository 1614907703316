import React, {useEffect, useState} from 'react';
import JsonData from "../data/data.json";

const AboutPage = () => {
    const [data, setData] = useState({});
    useEffect(() => {
        setData(JsonData);
    }, []);
    return (
        <section className="inner-pad">
            <div className="container grid--container">
                <div className="row">
                    <div className="col-12 col-ph-12">
                        <div className="section-heading">
                            <h3>This is the Cookie Policy for {data ? data.domain : "loading..."}</h3>
                            <p>Developer: {data.owner}</p>
                            <h5>What Are Cookies</h5>
                            <p>As is common practice with almost all professional websites this site uses cookies, which
                                are
                                tiny files that are downloaded to your computer, to improve your experience. This page
                                describes
                                what information they gather, how we use it and why we sometimes need to store these
                                cookies. We
                                will also share how you can prevent these cookies from being stored however this may
                                downgrade
                                or ‘break’ certain elements of the sites functionality. <br/> For more general
                                information on
                                cookies see the Wikipedia article on HTTP Cookies.</p>
                            <h5>How We Use Cookies</h5>
                            <p> We use cookies for a variety of reasons detailed below. Unfortunately in most cases
                                there
                                are no
                                industry standard options for disabling cookies without completely disabling the
                                functionality
                                and features they add to this site. It is recommended that you leave on all cookies if
                                you
                                are
                                not sure whether you need them or not in case they are used to provide a service that
                                you
                                use. </p>
                            <h5>Disabling Cookies</h5>
                            <p> You can prevent the setting of cookies by adjusting the settings on your browser (see
                                your
                                browser Help for how to do this). Be aware that disabling cookies will affect the
                                functionality
                                of this and many other websites that you visit. Disabling cookies will usually result in
                                also
                                disabling certain functionality and features of the this site. Therefore it is
                                recommended
                                that
                                you do not disable cookies. </p>
                            <h5>The Cookies We Set</h5>
                            <p> Account related cookies <br/> If you create an account with us then we will use cookies
                                for
                                the
                                management of the signup process and general administration. These cookies will usually
                                be
                                deleted when you log out however in some cases they may remain afterwards to remember
                                your
                                site
                                preferences when logged out. <br/> Login related cookies <br/> We use cookies when you
                                are
                                logged
                                in so that we can remember this fact. This prevents you from having to log in every
                                single
                                time
                                you visit a new page. These cookies are typically removed or cleared when you log out to
                                ensure
                                that you can only access restricted features and areas when logged in. <br/> Email
                                newsletters
                                related cookies <br/> This site offers newsletter or email subscription services and
                                cookies
                                may
                                be used to remember if you are already registered and whether to show certain
                                notifications
                                which might only be valid to subscribed/unsubscribed users. </p>
                            <h5>Third Party Cookies</h5>
                            <p> In some special cases we also use cookies provided by trusted third parties. The
                                following
                                section details which third party cookies you might encounter through this
                                site. <br/> This
                                site
                                uses Google Analytics which is one of the most widespread and trusted analytics solution
                                on
                                the
                                web for helping us to understand how you use the site and ways that we can improve your
                                experience. These cookies may track things such as how long you spend on the site and
                                the
                                pages
                                that you visit so we can continue to produce engaging content. For more information on
                                Google
                                Analytics cookies, see the official Google Analytics page. From time to time we test new
                                features and make subtle changes to the way that the site is delivered. When we are
                                still
                                testing new features these cookies may be used to ensure that you receive a consistent
                                experience whilst on the site whilst ensuring we understand which optimisations our
                                users
                                appreciate the most. The Google AdSense service we use to serve advertising uses a
                                DoubleClick
                                cookie to serve more relevant ads across the web and limit the number of times that a
                                given
                                ad
                                is shown to you. For more information on Google AdSense see the official Google AdSense
                                privacy
                                FAQ. </p>
                            <h5>More Information</h5>
                            <p>Hopefully that has clarified things for you and as was previously mentioned if there is
                                something
                                that you aren’t sure whether you need or not it’s usually safer to leave cookies enabled
                                in
                                case
                                it does interact with one of the features you use on our site. </p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutPage;