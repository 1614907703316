import React, {useEffect, useState} from 'react';
import JsonData from "../data/data.json";

const MainPage = () => {
    const [data, setData] = useState({});
    useEffect(() => {
        setData(JsonData);
    }, []);
    return (
        <main className="main-area">

            <section className="hero-banner" data-scroll-index="1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-10 col-xl-10 col-md-10 wow fadeInUp">
                            <div className="hero-inner">
                                <h1 className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0s">
                                    Free Social Casino Game
                                </h1>
                                <h2 className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.2s">
                                    {data ? data.title : "loading..."}
                                </h2>
                                <h2 className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s">
                                    {data ? data.p : "loading..."}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="features" data-scroll-index="3">
                <div className="container">
                    <div>
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="section-heading">
                                    <h2>
                                        <span>{data ? data.title2 : "loading..."}</span>
                                    </h2>
                                    <h4>
                                        {data ? data.p2 : "loading..."}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="features-img">
                                <img src="sfat/fav3.jpg" alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-minus-76" id="playnow" data-scroll-index="5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading-left light">
                                <h2>POPULAR FREE SOCIAL CASINO GAME "WOLF MOON RISING"</h2>
                            </div>
                            <div>
                                <iframe
                                    title={"Wolf Moon Rising"}
                                    src="https://egamings-c2ss.betsoftgaming.com/cwguestlogin.do?gameId=810&amp;lang=en&amp;bankId=792"
                                    frameBorder="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default MainPage;