import {useState} from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
    name: "",
    email: "",
    message: "",
};
export const Contact = (props) => {
    const [{name, email, message}, setState] = useState(initialState);
    const [modal, setModal] = useState(false);
    const handleChange = (e) => {
        const {name, value} = e.target;
        setState((prevState) => ({...prevState, [name]: value}));
    };
    const clearState = () => setState({...initialState});

    const handleSubmit = (e) => {
        console.log(name, email, message);
        if (name === "" || email === "" || message === "") {
            alert("Please fill in all fields");
            return;
        }
        setModal(true);
    };
    return (
        <section className="inner-pad">
            <div className="container grid--container">
                <div className="row">
                    <div className="col-12 col-ph-12">
                        <div id="contact">
                            <div className="container">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="section-title">
                                            <h2>Get In Touch</h2>
                                            <p>
                                                Please fill out the form below to send us an email and we will
                                                get back to you as soon as possible.
                                            </p>
                                        </div>
                                        {!modal ?
                                            <form name="sentMessage">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                id="name"
                                                                name="name"
                                                                className="form-control"
                                                                placeholder="Name"
                                                                required
                                                                onChange={handleChange}
                                                            />
                                                            <p className="help-block text-danger"></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="email"
                                                                id="email"
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="Email"
                                                                required
                                                                onChange={handleChange}
                                                            />
                                                            <p className="help-block text-danger"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                  <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                  ></textarea>
                                                    <p className="help-block text-danger"></p>
                                                </div>
                                                <div id="success"></div>
                                                <button type="submit" className="btn btn-custom btn-lg"
                                                        onClick={handleSubmit}>
                                                    Send Message
                                                </button>
                                            </form>
                                            :
                                            <div className="alert alert-success" role="alert">
                                                Your message has been sent successfully.
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
