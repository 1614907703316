import React, {useEffect, useState} from 'react';
import JsonData from "../data/data.json";

const AboutPage = () => {
    const [data, setData] = useState({});
    useEffect(() => {
        setData(JsonData);
    }, []);
    return (
        <section className="inner-pad">
            <div className="container grid--container">
                <div className="row">
                    <div className="col-12 col-ph-12">
                        <div className="section-heading">
                            <section className="section" id="features" data-scroll-index="3">
                                <div className="container">
                                    <div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10">
                                                <div className="section-heading">
                                                    <h2>
                                                        <span>{data ? data.title2 : "loading..."}</span>
                                                    </h2>
                                                    <h4>
                                                        {data ? data.p2 : "loading..."}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="features-img">
                                                <img src="sfat/fav3.jpg" alt="" className="img-fluid"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutPage;