import React, {useState, useEffect} from "react";
import {BrowserRouter as Router, NavLink, Route, Switch} from "react-router-dom";

import SmoothScroll from "smooth-scroll";
import {Header} from "./components/header";
import {Footer} from "./components/footer";
import MainPage from "./components/MainPage";
import AboutPage from "./components/AboutPage";
import PlayPage from "./components/PlayPage";
import PrivacyPage from "./components/PrivacyPage";
import TermsPage from "./components/TermsPage";
import CookiePage from "./components/CookiePage";
import {Contact} from "./components/contact";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

function App() {
    return (
        <main>
            <Router>
                <Header/>
                <Switch>
                    <Route exact path='/' component={MainPage}/>
                    <Route path='/about' component={AboutPage}/>
                    <Route path='/contact-us' component={Contact}/>
                    <Route path='/play' component={PlayPage}/>
                    <Route path='/privacy-policy' component={PrivacyPage}/>
                    <Route path='/terms-and-conditions' component={TermsPage}/>
                    <Route path='/cookie-policy' component={CookiePage}/>
                </Switch>
                <Footer/>
            </Router>
        </main>
    );
}

export default App;