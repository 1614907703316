import React, {useEffect, useState} from 'react';
import JsonData from "../data/data.json";

const AboutPage = () => {
    const [data, setData] = useState({});
    useEffect(() => {
        setData(JsonData);
    }, []);
    return (
        <section className="inner-pad">
            <div className="container grid--container">
                <div className="row">
                    <div className="col-12 col-ph-12">
                        <div className="section-heading">
                            <h2>Privacy Policy</h2>
                            <p>Developer: {data.owner}</p>
                            <p>This page informs you of our policies regarding the collection, use, and disclosure of
                                personal
                                data when you use our Service and the choices you have associated with that data. </p>
                            <p>We use your data to provide and improve the Service. By using the Service, you agree to
                                the
                                collection and use of information in accordance with this policy. Unless otherwise
                                defined
                                in
                                this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in
                                our
                                Terms and Conditions, accessible from {data ? data.domain : "loading..."} </p>
                            <h5>Information Collection And
                                Use</h5>
                            <p>We collect several different types of information for various purposes to provide and
                                improve
                                our
                                Service to you. </p>
                            <h5>Types of Data Collected</h5>
                            <p>Personal Data <br/> While using our Service, we may ask you to provide us with certain
                                personally
                                identifiable information that can be used to contact or identify you (“Personal Data”).
                                Personally identifiable information may include, but is not limited to: <br/> Email
                                address
                                <br/>
                                Phone number <br/> Cookies and Usage Data <br/>
                            </p>
                            <h5>Usage Data</h5>
                            <p>We may also collect information on how the Service is accessed and used (“Usage Data”).
                                This
                                Usage Data may include information such as your computer’s Internet Protocol address
                                (e.g.
                                IP
                                address), browser type, browser version, the pages of our Service that you visit, the
                                time
                                and
                                date of your visit, the time spent on those pages, unique device identifiers and other
                                diagnostic data. </p>
                            <h5>Tracking & Cookies Data</h5>
                            <p>We use cookies and similar tracking technologies to track the activity on our Service and
                                hold
                                certain information. <br/> Cookies are files with small amount of data which may include
                                an
                                anonymous unique identifier. Cookies are sent to your browser from a website and stored
                                on
                                your
                                device. Tracking technologies also used are beacons, tags, and scripts to collect and
                                track
                                information and to improve and analyze our Service. <br/> You can instruct your browser
                                to
                                refuse
                                all cookies or to indicate when a cookie is being sent. However, if you do not accept
                                cookies,
                                you may not be able to use some portions of our Service. You can learn more how to
                                manage
                                cookies in the Browser Cookies Guide.</p>
                            <h5>Examples of Cookies we use:</h5>
                            <p>Session Cookies. We use Session Cookies to operate our Service. Preference Cookies. We
                                use
                                Preference Cookies to remember your preferences and various settings. Security Cookies.
                                We
                                use
                                Security Cookies for security purposes. Use of Data {data ? data.domain : "loading..."} uses the
                                collected
                                data
                                for
                                various purposes: To provide and maintain the Service To notify you about changes to our
                                Service
                                To allow you to participate in interactive features of our Service when you choose to do
                                so
                                To
                                provide customer care and support To provide analysis or valuable information so that we
                                can
                                improve the Service To monitor the usage of the Service To detect, prevent and address
                                technical
                                issues Transfer Of Data Your information, including Personal Data, may be transferred to
                                —
                                and
                                maintained on — computers located outside of your state, province, country or other
                                governmental
                                jurisdiction where the data protection laws may differ than those from your
                                jurisdiction. If
                                you
                                are located outside Canada and choose to provide information to us, please note that we
                                transfer
                                the data, including Personal Data, to Canada and process it there. Your consent to this
                                Privacy
                                Policy followed by your submission of such information represents your agreement to that
                                transfer. {data ? data.domain : "loading..."} will take all steps reasonably necessary to ensure that
                                your
                                data
                                is
                                treated securely and in accordance with this Privacy Policy and no transfer of your
                                Personal
                                Data will take place to an organization or a country unless there are adequate controls
                                in
                                place
                                including the security of your data and other personal information.</p>
                            <h5>Disclosure Of
                                Data</h5>
                            <p>Legal Requirements <br/> {data ? data.domain : "loading..."} may disclose your Personal Data in the good
                                faith
                                belief
                                that such action is necessary to:</p>
                            <h5>To comply with a legal obligation</h5>
                            <p>To protect and defend the rights or property of {data ? data.domain : "loading..."} To prevent or
                                investigate
                                possible wrongdoing in connection with the Service To protect the personal safety of
                                users
                                of
                                the Service or the public To protect against legal liability</p>
                            <h5>Security Of Data</h5>
                            <p>The security of your data is important to us, but remember that no method of transmission
                                over
                                the Internet, or method of electronic storage is 100% secure. While we strive to use
                                commercially acceptable means to protect your Personal Data, we cannot guarantee its
                                absolute
                                security.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutPage;