import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import JsonData from "../data/data.json";

export const Footer = () => {
    const [data, setData] = useState({});
    useEffect(() => {
        setData(JsonData);
    }, []);
    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8 footer-widget-resp">
                            <div className="footer-widget">
                                <h6 className="footer-title">Disclaimer</h6>
                                <p className="footer-desc">
                                    {data ? data.owner : "loading..."} is delighted to welcome you. In order to participate, individuals
                                    must be at least 18 years old. There is no tangible currency involved—everything is
                                    purely for amusement. Ensure that you engage in suitable and proper behavior while
                                    playing. There is no assurance that engaging in activities here will result in
                                    obtaining any concrete rewards. We are a platform solely dedicated to providing
                                    entertainment, with no involvement in gaming activities. If you are experiencing
                                    difficulties with gaming, seek assistance. Engage in the activity with a sense of
                                    responsibility and derive enjoyment from it!
                                </p>
                                <img src="sfat/18.7.png" alt="footer logo"
                                     className="img-fluid footer-logo"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 footer-widget-resp">
                            <div className="footer-widget footer-widget-pl">
                                <h6 className="footer-title">Usefull Links</h6>
                                <ul className="footer-links">
                                    <li>
                                        <NavLink to={'/privacy-policy'} onClick={() => window.scrollTo(0, 0)}>
                                            <a>Privacy Policy</a>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/terms-and-conditions'} onClick={() => window.scrollTo(0, 0)}>
                                            <a>Terms &amp; Condition</a>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/cookie-policy'} onClick={() => window.scrollTo(0, 0)}>
                                            <a>Cookie Policy</a>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p className="copyright-text ">
                                © 2024 {data ? data.owner : "loading..."}. All rights
                                reserved. Use of this website
                                constitutes acceptance of our terms
                                of service.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
