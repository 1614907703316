import React from "react";
import {NavLink} from "react-router-dom";

export const Header = (props) => {
    return (
        <header className="header fixed-top header-shrink" id="header">
            <div id="nav-menu-wrap">
                <div className="container">
                    <nav className="navbar navbar-expand-lg p-0">
                        <NavLink to={'/'} onClick={() => window.scrollTo(0, 0)}>
                            <div className="navbar-brand" title="Home" style={{width: "130px"}}>
                                <img src="sfat/fav.png" alt="Logo White" className="img-fluid logo-normal"/>
                            </div>
                        </NavLink>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                                data-target="#fixedNavbar" aria-controls="fixedNavbar" aria-expanded="false"
                                aria-label="Toggle navigation">
              <span className="togler-icon-inner">
                <span className="line-1"></span>
                <span className="line-2"></span>
                <span className="line-3"></span>
              </span>
                        </button>
                        <div className="collapse navbar-collapse main-menu justify-content-end" id="fixedNavbar">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink to={'/about'} onClick={() => window.scrollTo(0, 0)}>
                                        <a className="nav-link menu-link">About Us</a>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'/contact-us'} onClick={() => window.scrollTo(0, 0)}>
                                        <a className="nav-link menu-link">Contact Us</a>
                                    </NavLink>
                                </li>
                                <li className="nav-item navbar-btn-resp d-flex align-items-center">
                                    <NavLink to={'/play'} onClick={() => window.scrollTo(0, 0)}>
                                        <a title="play" className="white-btn active">Play Wolf Moon Rising</a>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
};
