import React from 'react';

const PlayPage = () => {
    return (
        <section className="inner-pad">
            <div className="container grid--container">
                <div className="row">
                    <div className="col-12 col-ph-12">
                        <div className="section-heading">
                            <h2>Play "WOLF MOON RISING"</h2>
                        </div>

                        <div>
                            <iframe
                                title={"Wolf Moon Rising"}
                                src="https://egamings-c2ss.betsoftgaming.com/cwguestlogin.do?gameId=810&amp;lang=en&amp;bankId=792"
                                frameBorder="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PlayPage;